body {
  padding: 20px;
}

h1 {
  font-size: 32px;
  font-family: "Great Vibes", cursive;
  font-weight: 500;

  @media (min-width: 350px) {
    font-size: 36px;
  }

  @media (min-width: 480px) {
    font-size: 52px;
  }

  @media (min-width: 580px) {
    font-size: 64px;
  }
}

.app {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.row {
  @media (min-width: 900px) {
    display: flex;
  }
}

.title {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-top: 32px;

  @media (min-width: 380px) {
    gap: 32px;
  }

  @media (min-width: 580px) {
    gap: 40px;
    margin-top: 54px;
  }
}

.image {
  width: 70px;
  height: 70px;

  @media (min-width: 380px) {
    width: 80px;
    height: 80px;
  }

  @media (min-width: 480px) {
    width: 100px;
    height: 100px;
  }

  @media (min-width: 580px) {
    width: 120px;
    height: 120px;
  }
}

.personal-info {
  padding: 30px;
  width: auto;
}

@media (min-width: 900px) and (max-width: 1150px) {
  .personal-info {
    width: min-content;
  }
}

@media (min-width: 1151px) {
  .personal-info {
    width: auto;
  }
}
