.services {
  max-width: 500px;
  margin: 0 auto;
  padding: 30px;

  @media (min-width: 1300px) {
    max-width: 600px;
  }
}

.services h2 {
  margin-top: 20px;
}

.services h3 {
  margin-top: 28px;
}

.services p {
  line-height: 1.6;
  margin: 8px 0;
}

.services strong {
  display: block;
  margin-top: 10px;
}

.type {
  margin-bottom: 50px;
}

a {
  color: black;
}
