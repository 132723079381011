.contact-info {
  max-width: 600px;
  margin-bottom: 50px;
}

.contact-info ul {
  list-style-type: none;
  padding: 0;
}
.contact-info li {
  margin-bottom: 15px;
}
.contact-info label {
  font-weight: bold;
}
